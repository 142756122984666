import { formatTime } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { DEFAULT_TIMEZONE } from "@src/app/attendancePolicy/constants";
import { ShiftGeofence } from "@src/lib/interface/src";
import { differenceInMinutes, isAfter, isBefore, startOfMinute, subMinutes } from "date-fns";

import {
  allowedClockInDifferenceInMinutes,
  allowedClockOutDifferenceInMinutes,
} from "../constants/shiftEventTimeDifference";
import {
  breakEndBeforeBreakStart,
  breakEndEmpty,
  breakEndRadarError,
  breakStartRadarError,
  clockInAfterShiftEndError,
  clockInBeforeOneHourError,
  clockInEmpty,
  clockInOutEmpty,
  clockInRadarError,
  clockOutAfterTwoHoursError,
  clockOutBeforeClockIn,
  clockOutBeforeShiftStart,
  clockOutEmpty,
  clockOutPostActualClockOut,
  earlyClockInIsDisabledError,
  eventTimeNotInOrder,
} from "../constants/shiftEventTimeErrorMessages";

const GRACE_ENTRY_TIME_IN_MIN = 5;

interface IBreakEndTime {
  time?: string;
  breakStartTime?: string;
  geofence?: ShiftGeofence;
  timezone?: string;
}

interface IBreakStartTime {
  time?: string;
  geofence?: ShiftGeofence;
  timezone?: string;
}

interface IClockInTime {
  time: string;
  shiftStart: string;
  shiftEnd: string;
  isEarlyClockInEnabled: boolean;
  geofence?: ShiftGeofence;
  timezone?: string;
}

interface IClockOutTime {
  time: string;
  shiftStart: string;
  recordedClockOutTime?: string;
  shiftEnd: string;
  timezone?: string;
}

export function getClockInTimeError({
  time,
  shiftStart,
  shiftEnd,
  isEarlyClockInEnabled,
  geofence,
  timezone = DEFAULT_TIMEZONE,
}: IClockInTime): string {
  const clockInTime = startOfMinute(new Date(time));
  const shiftStartTime = startOfMinute(new Date(shiftStart));
  const shiftEndTime = startOfMinute(new Date(shiftEnd));

  if (geofence?.entry) {
    const geofenceEntryWithGraceTime = subMinutes(
      startOfMinute(new Date(geofence.entry)),
      GRACE_ENTRY_TIME_IN_MIN
    );

    if (isBefore(clockInTime, geofenceEntryWithGraceTime)) {
      const acceptedTime = formatTime(geofenceEntryWithGraceTime, {
        timeZone: timezone,
      });
      return clockInRadarError.replace(/%s/g, acceptedTime);
    }
  }
  if (!isEarlyClockInEnabled && isBefore(clockInTime, shiftStartTime)) {
    return earlyClockInIsDisabledError;
  }
  const startToClockInDifferenceInMinutes = differenceInMinutes(shiftStartTime, clockInTime);
  if (startToClockInDifferenceInMinutes > allowedClockInDifferenceInMinutes) {
    return clockInBeforeOneHourError;
  }
  if (isAfter(clockInTime, shiftEndTime)) {
    return clockInAfterShiftEndError;
  }
  return "";
}

export function getClockOutTimeError({
  time,
  shiftStart,
  recordedClockOutTime,
  shiftEnd,
  timezone = DEFAULT_TIMEZONE,
}: IClockOutTime): string {
  const clockOutTime = startOfMinute(new Date(time));
  const shiftStartTime = startOfMinute(new Date(shiftStart));
  const shiftEndTime = startOfMinute(new Date(shiftEnd));

  if (differenceInMinutes(clockOutTime, shiftEndTime) > allowedClockOutDifferenceInMinutes) {
    return clockOutAfterTwoHoursError;
  }
  if (isDefined(recordedClockOutTime)) {
    const roundedRecordedClockOutTime = startOfMinute(new Date(recordedClockOutTime));

    if (isAfter(clockOutTime, roundedRecordedClockOutTime)) {
      const acceptedTime = formatTime(roundedRecordedClockOutTime, {
        timeZone: timezone,
      });
      return clockOutPostActualClockOut.replace(/%s/g, acceptedTime);
    }
  }
  if (isBefore(clockOutTime, shiftStartTime)) {
    return clockOutBeforeShiftStart;
  }
  return "";
}

export function getBreakEndTimeError({
  time,
  breakStartTime,
  geofence,
  timezone = DEFAULT_TIMEZONE,
}: IBreakEndTime): string {
  if (!time) {
    return "";
  }
  const breakEndTime = startOfMinute(new Date(time));

  if (geofence?.endBreak) {
    const geofenceBreakEndWithGraceTime = subMinutes(
      startOfMinute(new Date(geofence.endBreak)),
      GRACE_ENTRY_TIME_IN_MIN
    );
    if (isBefore(breakEndTime, geofenceBreakEndWithGraceTime)) {
      const acceptedTime = formatTime(geofenceBreakEndWithGraceTime, { timeZone: timezone });
      return breakEndRadarError.replace(/%s/g, acceptedTime);
    }
  }
  if (breakStartTime && isBefore(breakEndTime, startOfMinute(new Date(breakStartTime)))) {
    return breakEndBeforeBreakStart;
  }
  return "";
}

export function getBreakStartTimeError({
  time,
  geofence,
  timezone = DEFAULT_TIMEZONE,
}: IBreakStartTime): string {
  if (!time) {
    return "";
  }
  const breakStartTime = startOfMinute(new Date(time));

  if (geofence?.startBreak) {
    const geofenceBreakStart = startOfMinute(new Date(geofence.startBreak));

    if (isAfter(breakStartTime, geofenceBreakStart)) {
      const acceptedTime = formatTime(geofenceBreakStart, {
        timeZone: timezone,
      });
      return breakStartRadarError.replace(/%s/g, acceptedTime);
    }
  }
  return "";
}

export function validateShiftTime({
  clockInTime,
  clockOutTime,
  breakStartTime,
  breakEndTime,
}: {
  clockInTime?: string;
  clockOutTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
}): string {
  const errorMessage = validateShiftTimesFilled({
    clockInTime,
    clockOutTime,
    breakStartTime,
    breakEndTime,
  });
  if (errorMessage) {
    return errorMessage;
  }

  // Check when break is skipped clock-in and clock-out are in order
  if (!breakStartTime && !breakEndTime && clockInTime && clockOutTime) {
    const roundedClockInTime = startOfMinute(new Date(clockInTime));
    const roundedClockOutTime = startOfMinute(new Date(clockOutTime));

    if (isAfter(roundedClockInTime, roundedClockOutTime)) {
      return clockOutBeforeClockIn;
    }
  }
  // Check event times are in order - clock-in<break-start<break-end<clock-out
  if (breakStartTime && breakEndTime && clockInTime && clockOutTime) {
    const roundedClockInTime = startOfMinute(new Date(clockInTime));
    const roundedClockOutTime = startOfMinute(new Date(clockOutTime));
    const roundedBreakStartTime = startOfMinute(new Date(breakStartTime));
    const roundedBreakEndTime = startOfMinute(new Date(breakEndTime));

    const areEventsInOrder =
      isAfter(roundedClockOutTime, roundedBreakEndTime) &&
      isAfter(roundedBreakEndTime, roundedBreakStartTime) &&
      isAfter(roundedBreakStartTime, roundedClockInTime);

    if (!areEventsInOrder) {
      return eventTimeNotInOrder;
    }
  }
  return "";
}

function validateShiftTimesFilled({
  clockInTime,
  clockOutTime,
  breakStartTime,
  breakEndTime,
}: {
  clockInTime?: string;
  clockOutTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
}): string {
  //Check if clock-in is filled and clock-out is not
  if (!clockInTime && clockOutTime) {
    return clockInEmpty;
  }
  //Check if clock-out is filled and clock-in is not
  if (clockInTime && !clockOutTime) {
    return clockOutEmpty;
  }
  //Check if break-start and break-end are filled and clock-in and clock-out are not
  if ((!clockInTime || !clockOutTime) && (breakStartTime || breakEndTime)) {
    return clockInOutEmpty;
  }
  //Check if break-start is filled and break-end is not or vice versa
  if ((!breakStartTime && breakEndTime) || (!breakEndTime && breakStartTime)) {
    return breakEndEmpty;
  }
  return "";
}
